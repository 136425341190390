import React from "react"
import styled from "styled-components"

import { rhythm } from "../utils/typography"
import TitleBar from "./title-bar"

class Layout extends React.Component {
  render() {
    const { title, children } = this.props

    return (
      <Wrapper>
        <header>
          <TitleBar name={title}></TitleBar>
        </header>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <main style={{marginTop: `80px`}}>{children}</main>
        </div>
        <Footer></Footer>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

export default Layout
