import React from "react";
import './title-bar.css';

function TitleBar(props) {
  return (
    <div className="title-bar">
      <a className="title-bar__headline" href="/">{props.name}</a>
    </div>
  )
}

export default TitleBar
